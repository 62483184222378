import React, { Component } from 'react';
import {
    getErrMsg,
    cmsGetLatestServiceSyncJobs,
    cmsGetServicesPromise,
    cmsGetAccountServicePromise
} from '../CallMSAPI.js';
import { cmsStartSyncAllServices } from '../SyncHelpers.js';
import { openPopupWindow } from '../ExternalAuthProvider.js';
import { serviceCodeToIconProps, isSystemOwner, isSyncOnlyContributor } from '../CallMSUIHelpers.js';
import { toast } from 'react-toastify';
import { DefaultButton } from 'office-ui-fabric-react';
import { withRouter } from 'react-router-dom';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { serviceNameExpander } from './ServiceFormHelpers.js';
import { Shimmer, ShimmerElementType } from '@fluentui/react';

import { connect } from 'react-redux';
import * as actions from '../store/actions/index';
import IdleTimeOut, { idleTimeout } from '../helpers/idleTimeOut.js';
import { SyncInterruptedHOC } from '../components/speciality/Offline';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm';

// Cover older browsers
var includes = require('array-includes');
var promiseFinally = require('promise.prototype.finally');
var moment = require('moment-timezone');

includes.shim();
promiseFinally.shim();


var _ = require('lodash');

/**
 *  This will query for the sync status on timer and show the output accordingly.
 *  The service ID gets passed in as a prop...
 * 
 */
class ServiceSync extends Component {
    constructor(props) {
        super(props);

        this.state = {
            serviceJobs: {},
            forceSetUpdating: false,
            lastUpdateDebug: false,
            promptUser: false,

            // Flag to aid in when to show toast error
            firstRun: true,

            // modal for detailed sync markdown messages
            modalMarkdown: '',
            openModal: false,
            shouldUpdate: true,
            isSyncButtonHidden: true
        };

        this.syncTimer = null;

        this.isRunningSync = false;

        // TEMP, anti pattern really, we should push out
        this.mounted = null;
        this.idleTimeoutUnmount = null;

        this.syncActionClickDebug = this.syncActionClickDebug.bind(this);
        this.syncActionClick = this.syncActionClick.bind(this);
        this.updateSyncJobState = this.updateSyncJobState.bind(this);
        this.nextGenTeamsSyncTimer = this.nextGenTeamsSyncTimer.bind(this);
        this.showHistoryClick = this.showHistoryClick.bind(this);
        this.syncCheck = this.syncCheck.bind(this);
        this.postUpdateSyncJobCb = this.postUpdateSyncJobCb.bind(this);

        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.showMarkdownPopup = this.showMarkdownPopup.bind(this);
    }

    showModal() {
        this.setState({ openModal: true })
    }

    hideModal() {
        this.setState({ openModal: false })
    }

    init = () => {
        this.initIdleTimeOut();
        this.setState({ nextGenSyncEnabled: this.props.account.IsBetaFeaturesEnabled && this.props.account.IsBetaFeaturesEnabled.includes('ENABLENEXTGENSYNC') });
    }

    initSyncJobs = () => {
        this.mounted = true;
        this.updateSyncJobState(this.postUpdateSyncJobCb);
    }

    initIdleTimeOut = () => {
        let self = this;

        self.initSyncJobs();

        self.idleTimeoutUnmount = idleTimeout(30, () => {
            if (!self.mounted && !self.isRunningSync) {
                self.initSyncJobs();
            }
        }, () => {
            if (!self.isRunningSync) {
                self.clearSync();
            }
        });
    }

    clearSync = () => {
        this.mounted = false;
        clearTimeout(this.syncTimer);
    }

    getEnabledServices = () => {
        return this.props.services.filter(x => x.SyncEnabled);
    }

    getEnabledServicesPromise = (isLongPolling = false) => {
        let self = this;
        return new Promise((res) => {
            if (isLongPolling) {
                self.props.refetchServices(resp => {
                    res(resp.rawList.filter(x => x.SyncEnabled));
                });
            } else {
                res(self.props.services.filter(x => x.SyncEnabled));
            }
        });
    }

    findIndexRequiredSyncServices = (id = '') => {
        return this.props.services.findIndex(x => id ? x.Id === id : x.SyncRequired);
    }

    componentDidMount() {
        this.init();
    }

    componentWillUnmount() {
        this.idleTimeoutUnmount();
        this.clearSync();
    }

    syncCheck(intervalSeconds) {
        var self = this;
        return setTimeout(function () {
            if (self.state.shouldUpdate) {
                self.updateSyncJobState(self.postUpdateSyncJobCb, intervalSeconds > 1000);
            }
        }, intervalSeconds)
    }

    postUpdateSyncJobCb(hasInProgressJob) {
        var delay = !hasInProgressJob ? 60 * 1000 : 10 * 1000;
        this.syncTimer = this.syncCheck(delay);
    }

    updateSyncJobState(optCb = () => { }, isLongPolling = false) {
        var self = this;

        this.getEnabledServicesPromise(isLongPolling)
            .then(enabledServices => {
                console.log("Update sync job running...");

                if (!self.mounted) {
                    console.log("Component has now been unmounted...");
                    return;
                }

                if (!enabledServices) {
                    console.log("No services, exiting servicesync promise");
                    optCb && optCb(false);
                    self.setState({ isSyncButtonHidden: false });
                    return;
                }

                var all = Promise.all(
                    _.map(
                        enabledServices,
                        function (service) {
                            return cmsGetLatestServiceSyncJobs(
                                self.props.account.Id,
                                service.Id
                            );
                        }
                    )
                );

                all.then(function (data) {
                    if (!self.mounted) {
                        console.log("Component has now been unmounted, pre set state...");
                        return;
                    }

                    var showToastWarnings = [];
                    var showToastErrors = [];
                    var serviceJobs = {};
                    data.forEach(function (resp) {
                        if (resp.data && resp.data.Results && resp.data.Results.length) {
                            var d = resp.data.Results[0];
                            var old = self.state.serviceJobs[d.AccountServiceId];

                            var serviceObj = _.find(enabledServices, function (s) {
                                return (s && s.Id && d && d.AccountServiceId && s.Id === d.AccountServiceId);
                            });
                            var serviceText = '';
                            if (serviceObj) {
                                serviceText = serviceObj.Name + ' Service - ';
                            }

                            if (!self.state.firstRun && old && d
                                && (
                                    (
                                        // New job has failed
                                        old.Id !== d.Id
                                        && d.State === "Failed"
                                    )
                                    ||
                                    (
                                        // Same job has gone from running to failed
                                        old.Id === d.Id
                                        && old.State === "Running"
                                        && d.State === "Failed"
                                    )
                                )
                            ) {
                                if (d.Advice) {
                                    showToastWarnings.push("Last sync failed: " + d.Advice);
                                } else {
                                    showToastErrors.push(serviceText + "Last sync failed.");
                                }
                            }

                            serviceJobs[d.AccountServiceId] = d;
                            console.log("Service sync status updated for => " + d.AccountServiceId);
                        }
                    });

                    self.setState({
                        serviceJobs: serviceJobs,
                        // Don't need force update anymore as real results are in
                        forceSetUpdating: self.state.nextGenSyncTriggered,
                        lastUpdateDebug: false,
                        firstRun: false,
                        isSyncButtonHidden: false
                    }, function () {
                        showToastWarnings.forEach(function (msg) {
                            // Advice, so should individually
                            toast.warning(msg);
                        });
                        if (showToastErrors.length) {
                            // Non advice errors, so combine
                            toast.error(showToastErrors.join(' '));
                        }

                        var hasSyncInProgress = false;
                        console.log("Checking sync in progress state: " + hasSyncInProgress);

                        if (enabledServices) {
                            var summaryStates = self.serviceJobsToSummaryState();
                            hasSyncInProgress = summaryStates["Queued"] || summaryStates["Running"] || self.state.nextGenSyncTriggered ? true : false;
                        }

                        console.log("Checking sync in progress state: " + hasSyncInProgress);
                        optCb && optCb(hasSyncInProgress);
                    })
                }, function (_err) {
                    toast.error("Unable to get latest status!");
                    optCb && optCb(false);
                }).catch(function (_err) {
                    toast.error("Unable to get services on account");
                    optCb && optCb(false);
                })
            });
    }

    nextGenTeamsSyncTimer() {
        var self = this;
        self.setState({ nextGenSyncTriggered: true });
        setTimeout(function () {
            self.setState({ nextGenSyncTriggered: false });
        }, 30000)
    }

    showHistoryClick() {
        this.props.history.push(`/portal/${this.props.account.Id}/services/jobs`);
    }

    syncActionClickDebug(e, serviceWrapper) {
        return this.syncActionClick(e, serviceWrapper, true);
    }

    syncActionClick(e, serviceWrapper, forceDebug = false, isManualSync) {
        var self = this;

        var captureDebug = false;
        if (e !== undefined && e.shiftKey && e.altKey) {
            console.log("Capturing Debug for Sync");
            captureDebug = true;
        }
        if (forceDebug) {
            console.log("Force debug triggered for this sync");
            captureDebug = true;
        }

        self.setState({ shouldUpdate: false }, () => {

            var promptUserCapture = self.state.promptUser;

            // Immediate feedback to user about the update in progress
            self.setState({
                forceSetUpdating: true,
                lastUpdateDebug: captureDebug,
                promptUser: false,
            }, function () {
                var SyncQueue = [];
                var UnsupportedId = [];
                var willRequirePopup = 0;

                var servicesToProcess = [];
                if (serviceWrapper === undefined) {
                    // Was called with main 'all' button
                    servicesToProcess = self.getEnabledServices();
                } else {
                    servicesToProcess.push(serviceWrapper.service);
                }

                servicesToProcess.forEach(function (service) {
                    if (service.ServiceCode === 'teams' || service.ServiceCode === 'sfb') {
                        service['_Priority'] = 1;
                        SyncQueue.push(service);

                        /**
                         * Try avoid central popup management unless required. To fix:
                         * To support teams and sfb sharing an auth window we need to track nonce between URI request
                         **/
                        // willRequirePopup++;
                    } else if (service.ServiceSyncModuleCode) {
                        // external pbx style sync
                        service['_Priority'] = 10;
                        SyncQueue.push(service);

                        // Only trigger external popup management when we have PBX to work with
                        willRequirePopup++;
                    } else {
                        UnsupportedId.push(service.Name);
                    }
                });

                if (UnsupportedId.length) {
                    toast.info("Sync is not supported for service(s): " + UnsupportedId.join(", "));
                }

                var popup = null;
                if (willRequirePopup > 0) {
                    popup = openPopupWindow('about:blank', "Portal Auth");
                }

                // Need to expand these services to full blown objects so we can get SyncSettings (e.g. link with last used domain etc)
                var expandServices = [];
                _.forEach(SyncQueue, function (s) {
                    expandServices.push(
                        cmsGetAccountServicePromise(self.props.account.Id, s.Id)
                    );
                });

                Promise.allSettled(expandServices).then(
                    function (responses) {
                        _.forEach(responses, function (r) {
                            if (r.status !== 'fulfilled') {
                                console.log(r);
                                throw new Error("Unable to grab all full service objects");
                            }
                        });

                        // Go through syncqueue and expand the objects...
                        var outSyncQueue = [];
                        _.forEach(SyncQueue, function (s) {
                            var fullResponse = _.find(responses, function (r) {
                                return (r && r.value && r.value.data && r.value.data.Id === s.Id ? true : false);
                            });

                            if (fullResponse !== null) {
                                var fullS = fullResponse.value.data;
                                // Carry through priority ahead of sort
                                fullS['_Priority'] = s['_Priority'];
                                // Map to ServiceCode to have constent handle below
                                fullS['ServiceCode'] = fullS.Variant.ServiceCode;
                                outSyncQueue.push(fullS);
                            } else {
                                outSyncQueue.push(s);
                            }
                        });

                        outSyncQueue = _.sortBy(outSyncQueue, '_Priority');

                        let isAutoSync = !isManualSync ? (self.props.fullTeams.filter(x => x.TeamsSettings && x.TeamsSettings.IsAutoSyncEnabled).length > 0) : false;

                        return cmsStartSyncAllServices(self.props.account.Id, outSyncQueue, popup, captureDebug, promptUserCapture, isAutoSync).then(function (lastData) {
                            // After all those syncs, only thing we need to do is close the popup if we ourselves handled creating it.
                            if (willRequirePopup > 0) {
                                popup.close();
                            }
                        }, function (error) {
                            toast.error("Unable to start the sync: " + error);

                            // Ensure the next run prompts the user for credentials again
                            self.setState({ promptUser: true });
                        }).finally(function () {
                            if (outSyncQueue.some(item => item.ServiceCode === 'teams') && self.state.nextGenSyncEnabled) {
                                self.nextGenTeamsSyncTimer();
                            }
                            self.updateSyncJobState(self.postUpdateSyncJobCb);
                            self.setState({ shouldUpdate: true });
                        });

                    }, function (err) {
                        toast.error("Unable to expand service details: " + getErrMsg(err));
                        self.setState({ shouldUpdate: true });
                    }
                );
            });
        });

    }

    serviceJobsToSummaryState() {
        var self = this;

        var states = {
            "Queued": false,
            "Running": false,
            "Failed": false,
            "Cancelled": false,
            "Completed": false,
            "RunningInDebug": false,
            "ManualIntervention": false,
            "AdviceMessages": [],
            "ProgressMessages": [],
            "NextSync": []
        };

        this.props.services.forEach(function (s) {
            if (self.state.serviceJobs.hasOwnProperty(s.Id)) {
                states[self.state.serviceJobs[s.Id].State] = true;

                if (["Running", "Queued"].includes(self.state.serviceJobs[s.Id].State)
                    && self.state.serviceJobs[s.Id].CaptureDebug
                ) {
                    states["RunningInDebug"] = true;
                }

                if (self.state.serviceJobs[s.Id].Advice) {
                    states["AdviceMessages"].push(self.state.serviceJobs[s.Id].Advice);
                }

                if (self.state.serviceJobs[s.Id].ProgressAdvice) {
                    states["ProgressMessages"].push(self.state.serviceJobs[s.Id].ProgressAdvice);
                }

                if (self.state.serviceJobs[s.Id].NextSyncDue) {
                    states["NextSync"].push(self.state.serviceJobs[s.Id].NextSyncDue);
                }
            }
        });

        const { forceSetUpdating } = this.state;
        //for idleTimeout logic...
        if (states["Queued"] || states["Running"] || forceSetUpdating) {
            this.isRunningSync = true;
        } else {
            this.isRunningSync = false;
        }

        return states;
    }

    showMarkdownPopup(source) {
        this.setState({
            modalMarkdown: source,
            openModal: true
        });
    }

    render() {
        var self = this;
        let isDdlVisible = isSyncOnlyContributor(self.props.baseAccountInfo.Roles);
        const enabledServices = self.getEnabledServices();

        const { isSyncButtonHidden } = this.state;

        // Check at least one service is enabled for sync...
        if (isSyncButtonHidden && enabledServices.length > 0) {

            let shimmerElement = [
                { type: ShimmerElementType.line, width: '140px', height: 30 },
            ]
            return (
                <div className="sync-button-wrapper">
                    <Shimmer shimmerElements={shimmerElement} />
                </div>
            );
        } else if (enabledServices.length <= 0) {
            return null;
        }

        var summaryStates = self.serviceJobsToSummaryState();
        var syncQueued = summaryStates["Queued"] ? true : false;
        var syncRunning = summaryStates["Running"] ? true : false;
        var syncFailed = summaryStates["Failed"] ? true : false;
        var syncCancelled = summaryStates["Cancelled"] ? true : false;
        var syncCompleted = summaryStates["Completed"] ? true : false;
        var syncManualIntervention = summaryStates["ManualIntervention"] ? true : false;
        var syncMessages = summaryStates["AdviceMessages"];
        var syncProgressMessages = summaryStates["ProgressMessages"];

        var syncDebug = self.state.lastUpdateDebug;
        if (!syncDebug) {
            syncDebug = summaryStates["RunningInDebug"];
        }

        var syncRequired = (this.findIndexRequiredSyncServices() !== -1 ? true : false) || syncManualIntervention;

        var isMulti = (enabledServices.length > 1 ? true : false);
        var isAutoSync = (self.props.fullTeams.filter(x => x.TeamsSettings && x.TeamsSettings.IsAutoSyncEnabled).length > 0);

        var isUpdating = self.state.forceSetUpdating;
        var actionText = "Sync Now";
        var hoverText = 'Sync';
        if (isMulti) {
            hoverText = 'Sync All';
        }

        var nextSyncDue = null;
        if (summaryStates["NextSync"].length > 0) {
            var t = moment.tz(summaryStates["NextSync"][0], "UTC");
            if (t.isValid()) {
                if (!t.isAfter()) {
                    nextSyncDue = <span>Setup partially complete, click Sync Now.</span>;
                    syncRequired = true;
                } else {
                    nextSyncDue = <span>Setup partially complete, sync again in {t.fromNow()}.</span>;
                }
            }
        }

        var statusTextClass = 'text-normal';
        var iconClassNames = 'fa';
        let iconMenuItem = '';
        if (syncQueued || syncRunning || isUpdating) {
            if (syncDebug) {
                iconClassNames += ' fa-bug fa-spin';
                iconMenuItem = 'Bug';
            } else {
                iconClassNames += ' fa-refresh fa-spin';
                iconMenuItem = 'Refresh';
            }

            actionText = "Syncing";
            isUpdating = true;
        }

        if (syncQueued) {
            hoverText = 'Sync Queued';

        } else if (isUpdating || syncRunning) {
            hoverText = 'Sync In Progress';

        } else if (syncCancelled) {
            hoverText = 'Sync Cancelled';

        } else if (syncFailed) {
            hoverText = 'Sync Failed';
            iconClassNames += ' fa-warning';
            statusTextClass = 'text-error';
            iconMenuItem = 'Warning';

        } else if (syncRequired) {
            actionText = 'Sync Now - Changes Queued';
            iconClassNames += ' fa-warning';
            hoverText = "Sync Required";
            statusTextClass = 'text-notice';
            iconMenuItem = 'Warning';

        } else if (syncCompleted) {
            iconClassNames += ' fa-check';
            hoverText = 'Sync Complete';
            iconMenuItem = 'CheckMark';
            if (this.props.onSyncCompleteCallback) { this.props.onSyncCompleteCallback(); }
        }

        var opts = {};
        if (!isUpdating && syncRequired) {
            opts['primary'] = true;
            if (syncFailed) {
                opts['className'] = "ms-Button--danger";
            }
        }

        var childElements = [];

        if (isMulti || isAutoSync) {

            enabledServices.forEach((s) => {
                const sSyncJob = self.state.serviceJobs[s.Id];
                const isCurrentServiceSyncing = sSyncJob && (sSyncJob.State === "Running" || sSyncJob.State === "Queued");
                let isFailed = false;

                if (s.ServiceSyncModuleCode.indexOf('SYSTEM') > -1) {
                    isFailed = sSyncJob && (sSyncJob.State === "Failed" || sSyncJob.State === "Cancelled");
                }
                const name = serviceNameExpander(s);

                childElements.push(
                    {
                        key: s.Id,
                        name: name,
                        text: (<span className={isFailed ? "failed-state-sync" : ""}>{name}</span>),
                        iconProps: serviceCodeToIconProps(s.ServiceCode),
                        service: s,
                        onClick: self.syncActionClick,
                        disabled: isCurrentServiceSyncing,
                        className: 'warning'
                    }
                );

                if (s.ServiceSyncModuleCode === 'TEAMSSYSTEMSETUP') {
                    childElements.push(
                        {
                            key: s.Id + '_debug',
                            name: name,
                            text: (<span className={isFailed ? "failed-state-sync" : ""}>{name}&nbsp;<em>(Full Branding)</em></span>),
                            iconProps: serviceCodeToIconProps(s.ServiceCode),
                            service: s,
                            onClick: self.syncActionClickDebug,
                            disabled: isCurrentServiceSyncing,
                            className: 'warning'
                        }
                    );
                }
                if(isAutoSync && childElements.filter(x => x.name === 'Manual Sync').length === 0){
                    let match = enabledServices.find(x => x.Id === self.props.fullTeams[0].Id);
                    let sSyncJob = self.state.serviceJobs[match.Id];
                    let isCurrentServiceSyncing = sSyncJob && (sSyncJob.State === "Running" || sSyncJob.State === "Queued");
                    let child = {
                        key: s.Id,
                        name: 'Manual Sync',
                        text: (<span>Manual Sync</span>),
                        iconProps: serviceCodeToIconProps(match.ServiceCode),
                        service: match,
                        onClick: (e) => self.syncActionClick(e, child, null, true),
                        disabled: isCurrentServiceSyncing,
                        className: 'warning'
                    }
                    childElements.push(child);
                }
            });

        }

        // Sort to be alphabetical
        childElements = _.sortBy(childElements, 'name');

        childElements.splice(0, 0, {
            key: 'sync',
            iconProps: { iconName: iconMenuItem },
            text: actionText,
            className: `d-sm-none`,
            onClick: (e) => self.syncActionClick(e)
        });

        if (!isDdlVisible) {

            childElements.push(
                {
                    key: "history",
                    text: "Show Sync History",
                    onClick: self.showHistoryClick
                }
            );

        }

        isDdlVisible = childElements && childElements.length > 0;

        if (isDdlVisible) {
            opts['menuProps'] = {
                items: childElements
            }
        }

        var manualIntervention = null;
        if (syncManualIntervention) {
            manualIntervention = <span className="sync-manual-msg">Manual intervention is required.</span>;
        }

        var modal = (
            <Dialog
                hidden={!this.state.openModal}
                onDismiss={this.hideModal}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: 'Sync Advice',
                }}
                minWidth={750}
                maxWidth={850}
            >
                <ReactMarkdown className="sync-markdown-wrapper" remarkPlugins={[[remarkGfm, { singleTilde: false }]]}>
                    {this.state.modalMarkdown}
                </ReactMarkdown>

                <DialogFooter>
                    <DefaultButton onClick={this.hideModal} text="Close" />
                </DialogFooter>
            </Dialog>
        );

        var messages = null;
        if (nextSyncDue || manualIntervention || syncMessages.length || syncProgressMessages.length) {
            if (syncProgressMessages.length) {
                statusTextClass = 'text-notice';
            }

            messages = (
                <p className={"sync-messages " + statusTextClass}>
                    {nextSyncDue}
                    {manualIntervention}
                    {syncMessages.map(function (v, ind) {
                        if (v.indexOf("\n") === -1) {
                            return <span key={ind}>{v}</span>;
                        } else {
                            return <span key={ind}><button className="btn btn-link btn-link--faux" onClick={() => self.showMarkdownPopup(v)}>View Advice</button></span>
                        }
                    })}
                    {syncProgressMessages.map(function (v, ind) {
                        return <span key={ind}>{v}</span>
                    })}
                </p>
            );
        }

        var systemOwnerSystemAccount = false;
        var systemAccount = self.props.account.ParentId === null;
        var systemOwner = isSystemOwner(self.props.baseAccount.roles);
        if (systemAccount && systemOwner) {
            systemOwnerSystemAccount = true;
        }

        return (
            <SyncInterruptedHOC syncRunning={syncRunning}>
                <div className={'sync-button-wrapper' + (!isUpdating && !syncRunning && !syncFailed && syncRequired ? ' pulsingButton' : (!isUpdating && !syncRunning && syncFailed && syncRequired ? ' pulsingButtonFail' : ''))}>
                    <DefaultButton
                        {...opts}
                        primaryDisabled={isUpdating || systemOwnerSystemAccount}
                        disabled={isUpdating && !systemOwnerSystemAccount}
                        text={
                            <>
                                <i className={iconClassNames}></i> {actionText}
                            </>
                        }
                        onClick={this.syncActionClick}
                        split={isDdlVisible}
                        aria-roledescription={'split button'}
                        title={hoverText}
                    />
                    {messages}
                    {modal}
                </div>
            </SyncInterruptedHOC>
        );
    }

}
const mapStateToProps = state => {
    const services = state.services;
    const account = state.account;
    return {
        services: services.rawList,
        fullTeams: services.fullTeams,
        account: account.account,
        baseAccount: account.baseAccount,
        baseAccountInfo: account.baseAccountInfo
    };
}
const mapDispatchToProps = dispatch => {
    const callBack = () => { };
    return {
        refetchServices: (res = callBack) => dispatch(actions.refetchServices(res))
    };
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ServiceSync));

