import React, { Component } from 'react';
import { cmsGetServiceSyncJob } from '../../CallMSAPI.js';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm';

class SyncJobOutput extends Component { 
    constructor(props) {
        super(props);
        this.state = {
            fullData: null
        }
    }

   componentWillMount() {
        var self = this;
        cmsGetServiceSyncJob(
            self.props.account.Id,
            self.props.jobId,
            function (data) {
                self.setState({
                    fullData: data
                });
            }, function (err) {
                toast.error(`Unable to load full job data: ${err}`);
            }
        );
    }

    justCopiedAction() {
        toast.success("Job output copied to clipboard");
    }

    render() {
        var self = this;
        if (!this.state.fullData) {
            return <pre>Loading...</pre>;
        } else {
            var exceptionOutput = null;
            if (self.props.hasRole('ViewExceptions') && this.state.fullData.ExceptionOutput) {
                exceptionOutput = (
                    <>
                        <div className="py-1 row system-owner-action">
                            <div className="col-md-12 text-right">
                                <i className="fa-solid fa-user-secret" title="System Owner Only" style={{ float: 'left', padding: '8px' }}></i>
                                <CopyToClipboard text={this.state.fullData.ExceptionOutput} onCopy={self.justCopiedAction}>
                                    <button className="btn btn-link btn-xs">
                                        <span>Copy Exception Job Output</span> <i className="fa-solid fa-copy"></i>
                                    </button>
                                </CopyToClipboard>
                            </div>
                        </div>

                        <pre className="code-output-limit">
                            {this.state.fullData.ExceptionOutput}
                        </pre>
                    </>
                );
            }

            var debugOutput = null;
            if (this.state.fullData.CaptureDebug && this.state.fullData.DebugOutput) {
                debugOutput = (
                    <>
                        <div className="py-1 row">
                            <div className="col-md-12 text-right">
                                <CopyToClipboard text={this.state.fullData.DebugOutput} onCopy={self.justCopiedAction}>
                                    <button className="btn btn-link btn-xs">
                                        <span>Copy Debug Job Output</span> <i className="fa-solid fa-copy"></i>
                                    </button>
                                </CopyToClipboard>
                            </div>
                        </div>

                        <pre className="code-output-limit">
                            {this.state.fullData.DebugOutput}
                        </pre>
                    </>
                );
            }

            var advice = null;
            if (this.state.fullData.Advice) {
                advice = (
                    <>
                        <p><strong>Advice: </strong></p>
                        <ReactMarkdown className="sync-markdown-wrapper" remarkPlugins={[[remarkGfm, { singleTilde: false }]]}>
                            {this.state.fullData.Advice}
                        </ReactMarkdown>
                    </>
                );
            }

            return (
                <>
                    <div className="py-1 row">
                        <div className="col-md-6">
                            <p><strong>Sync Job Id: </strong>{this.state.fullData.Id}</p>
                        </div>
                        <div className="col-md-6 text-right">
                            <CopyToClipboard text={this.state.fullData.Output} onCopy={self.justCopiedAction}>
                                <button className="btn btn-link btn-xs">
                                    <span>Copy Job Output</span> <i className="fa-solid fa-copy"></i>
                                </button>
                            </CopyToClipboard>
                        </div>
                    </div>
                    <div className="py-1 row">
                        <div className="col-md-12">
                            {advice}
                        </div>
                    </div>

                    <hr />
                    <p><strong>Output:</strong></p>
                    <pre className="code-output-limit">
                        {this.state.fullData.Output}
                    </pre>

                    {debugOutput}

                    {exceptionOutput}
                </>
            );
        }
    }
}
export default connect(state => ({
    account: state.account.account
}), dispatch => ({
    hasRole: (uiPart = '') => dispatch(actions.hasRole(uiPart))
}))(SyncJobOutput);
